.Footer {
    padding: 0 30px;
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px);
    height: 40px;
    left: 0;
}

.Footer p {
    font-size: 12px;
    line-height: 40px;
    margin: 0;
}
