.pharmacists .add-btn {
    position: fixed;
    border: none;
    background: rgba(255, 255, 255, 0.548);
    top: 1.2rem;
    border-radius: 10px;
    z-index: 11;
    left: 30rem;
    padding: 1rem 2rem 1rem 2rem;
    transition: .2s;
}