.pharmacy {
    display: flex;
    flex-wrap: wrap;
}

.pharmacy .pharmacy-item {
    list-style: none;
    padding: 2rem;
    margin: 1rem 1rem 0 0;
    width: 22%;
    background-color: rgba(122, 118, 111, 0.068);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.pharmacy .pharmacy-item p {
    max-width: 90%;
    margin-top: 1rem;
}

.pharmacy .pharmacy-item .show-text {
    color: rgb(100, 148, 237);
    transition: .2s;
    opacity: .6;
    cursor: pointer;
}

.pharmacy .pharmacy-item .show-text:hover {
    opacity: 1;
    text-decoration: underline;
}

.pharmacy .pharmacy-item span {
    font-size: 3rem;
}

.pharmacy .pharmacy-item .icon-block {
    position: relative;
    bottom: -1.4rem;
    left: 9.5rem;
}

.pharmacy .qrCode {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.226);
}

.pharmacy .qrCode div {
    width: 30%;
    margin: 5rem auto;
}

.pharmacy .qrCode .qr-warn {
    background-color: rgb(255, 255, 255);
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 10px;
    width: 26rem;
}

.pharmacy .add-btn {
    position: fixed;
    border: none;
    background: rgba(255, 255, 255, 0.548);
    top: 1.2rem;
    border-radius: 10px;
    z-index: 11;
    left: 30rem;
    padding: 1rem 2rem 1rem 2rem;
    transition: .2s;
}

.pharmacy .add-btn:hover {
    background: rgba(185, 185, 185, 0.329);
}

@media screen and (max-width: 475px) {
    .pharmacy .pharmacy-item {
        width: 47%;
    }

    .pharmacy .qrCode div {
        width: 68%;
    }
}
