.counter {
    display: flex;
    align-items: center;
}

.counter .filter-block {
    width: 60%;
}

.counter .date-block {
    margin: 0 0 15px;
}

.counter .nb-block {
    text-align: center;
    /* margin-left: 20rem; */
    width: 30rem;
}

.counter .nb-block .loader {
    display: inline-block;
    width: 80px;
}

.counter .nb-block .loader::after {
    content: " ";
    display: block;
    width: 44px;
    height: 44px;
    margin-left: 20px;
    border-radius: 50%;
    border: 6px solid rgb(73, 73, 73);
    border-color: rgb(73, 73, 73) transparent rgb(73, 73, 73) transparent;
    animation: loader-anim 1.2s linear infinite;
}

.counter .filter-block .select-block {
    margin-bottom: 2rem;
}

.counter .filter-block .filter-block-user {
    display: flex;
}

.counter .filter-block .filter-block-user .select-block {
    margin-left: 30px;
}

.counter .filter-block .filter-block-user input {
    height: 34px;
    padding: 6px 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

@keyframes loader-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 476px) {
    .counter {
        display: block;
    }

    .counter input {
        width: 100%;
    }

    .counter .filter-block {
        width: 100%;
    }

    .counter .nb-block {
        margin-left: 1rem;
        margin-top: 2rem;
        width: 90%;
    }

    .counter .filter-block .filter-block-user {
        display: flex;
        flex-direction: column;
    }

    .counter .filter-block .filter-block-user .select-block {
        margin-left: 0px;
        width: 100%;
    }
}