.Header {
    background: #f2f2f2;
    position: fixed;
    top: 0;
    width: 100%;
    border-bottom: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    margin-bottom: 0;
    min-height: 66px;
    padding: 0 15px 0 0;
    z-index: 9;
}

.Header a.logo {
    line-height: 66px;
    font-weight: 600;
    color: #212121;
    font-size: 21px;
    text-decoration: none;
}

.Header a.logo img {
    vertical-align: middle;
    margin: 0 30px 0 15px;
}

.Header .right {
    height: 66px;
    float: right;
}

.Header .right ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    margin-right: 15px;
}

.Header .right ul li {
    position: relative;
    line-height: 66px;
}

.Header img.user {
    height: 42px;
    width: 42px;
    vertical-align: middle;
    border-radius: 21px;
}
