.login-page {
    width: 100%;
    padding: 8% 0 0;
    margin: auto;
}

@media (max-width: 1024px) {
    .login-page {
        padding: 20% 0 0;
    }
}

.login-page form {
    position: relative;
    z-index: 1;
    background: #ffffff;
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.login-page form input {
    font-family: 'Roboto', sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}
.login-page form input[type="submit"] {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #3ab895;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #ffffff;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}
.login-page form input[type="submit"]:hover,
.login-page form input[type="submit"]:active,
.login-page form input[type="submit"]:focus {
    background: #43fdc8;
    transition: 0.7s;
}

.background-container {
    position: relative;
    margin: 0px;
    padding: 0px;
    height: 100vh;
    min-height: 100vh;
    background: #3ab895; /* fallback for old browsers */
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.background-container .logo-feeli {
    height: 48px;
    position: absolute;
    top: 12px;
    left: 12px;
}