body {
    color: #878787;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 1.5;
    background: #acb0b1;
    overflow-x: hidden;
    margin: 0;
}

.wrapper {
    margin-left: 225px;
    position: relative;
    padding: 70px 20px;
    background: #fafafa;
}

.pT-25 {
    padding-top: 25px;
}

.mT-40 {
    margin-top: 40px;
}

.ctr {
    position: relative;
}

.pa-0 {
    padding: 0;
}

.blue {
    color: #09B1D0;
}

@media (max-width: 1024px) {
    .wrapper {
        margin-left: 0;
        padding: 70px 0;
    }
}
