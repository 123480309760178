.doctor-active {
    margin-top: 2rem;
}

.doctor-active .table-responsive {
    width: 50%;
}

.doctor-active .table-responsive td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
}

.doctor-active .table-responsive td i {
    font-size: 20px;
    margin-left: 2rem;
    color: green;
}

.doctor-active .table-responsive span {
    cursor: pointer;
}

.doctor-active button {
    border: none;
    margin-left: 2rem;
    font-size: 25px;
    padding: 0.3rem;
    margin-bottom: 7px;
    border-radius: 10px;
}