.dashboard .dashboard-global .dashboard-counter {
    display: flex;
    justify-content: space-around;
}

.dashboard .dashboard-global .dashboard-counter>div {
    margin: 20px;
    width: 20%;
    text-align: center;
}

.dashboard .dashboard-global .dashboard-counter .title {
    font-weight: bold;
    font-size: 20px;

}

.dashboard .icon {
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    width: 40px;
    height: 40px;
    text-align: center;
    align-self: center;
    padding-top: 10px;
}

.dashboard .today-counter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    gap: 30px;
}

.dashboard .today-counter>div {
    width: 230px;
}

@media screen and (max-width:992px) {
    .dashboard .dashboard-global .dashboard-counter {
        flex-direction: column;
    }

    .dashboard .dashboard-global .dashboard-counter>div {
        width: 100%;
    }
}

@media screen and (max-width:720px) {
    .dashboard .today-counter>div {
        width: 100%;
        gap: 0
    }
}