.Sidebar {
    position: fixed;
    top: 66px;
    left: 0;
    width: 225px;
    margin-left: 0;
    bottom: 0;
    z-index: 8;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.35);
    border-right: 1px solid #dedede;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.Sidebar ul {
    background: #f2f2f2;
    height: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
}

.Sidebar ul li.title span {
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #878787;
    padding: 11px 15px;
}

.Sidebar ul li a,
.Sidebar ul li .link {
    color: #212121;
    padding: 7px 15px;
    width: 100%;
    display: block;
    text-decoration: none;
    transition: 0.3s ease;
    cursor: pointer;
}

.Sidebar ul li.active > a,
.Sidebar ul li.active > .link {
    color: #2196f3;
    cursor: pointer;
}

.Sidebar ul li a:hover,
.Sidebar ul li .link:hover {
    transition: 0.3s ease;
    background: rgba(33, 33, 33, 0.05);
}

.Sidebar ul li i {
    font-size: 18px;
    width: 18px;
    margin-right: 20px;
    vertical-align: text-bottom;
}

@media (max-width: 1024px) {

    li.title {
        display: none;
    }

    .Sidebar {
        position: relative;
        width: 100%;
        text-align: center;
    }
}
