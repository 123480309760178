.cohorte table,
.cohorte th,
.cohorte td {
    border: 1px solid #000;
}

.cohorte {
    max-width: 85%;
}

.cohorte .table-container {
    position: relative;
    max-height: calc(100vh - 150px);
    width: 100%;
    overflow: scroll;
    margin-top: 20px;
    padding-right: 20px;
}

.cohorte th,
.cohorte td {
    text-align: center;
    width: 100px;
    padding: 0px 5px;
}

.cohorte table thead th {
    position: sticky;
    top: 0px;
    z-index: 1;
    background: rgba(98, 158, 217, 1);
    color: #fff;
}

.cohorte tr> :first-child {
    position: -webkit-sticky;
    position: sticky;
    background: rgba(98, 158, 217, 1);
    color: #fff;
    left: 0;
}

.cohorte table tbody tr:hover {
    background-color: #87878747;
}

.modal-spinner .modal-content {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
    text-align: center;
    margin-top: 300px;
}

.modal-spinner p {
    color: #000;
}

.loader {
    display: inline-block;
    width: 80px;
}

.loader::after {
    content: " ";
    display: block;
    width: 44px;
    height: 44px;
    margin-left: 20px;
    border-radius: 50%;
    border: 6px solid rgb(0, 0, 0);
    border-color: rgb(0, 0, 0) transparent rgb(0, 0, 0) transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cohorte .button {
    margin-left: 20px;
}

.cohorte select {
    height: 27px;
}