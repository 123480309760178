.r-analytics td,
.r-analytics th {
    text-align: center;
}

.r-analytics .heading>div {
    display: flex;
    margin-bottom: 5px;
}
.r-analytics .heading>div:nth-child(3), .r-analytics .heading>div:nth-child(4) {
   margin-left: 90px;
}

.r-analytics .heading .submit-button {
    margin-left: 20px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
}

.r-analytics .heading button:hover {
    opacity: 0.7;
}

.r-analytics .analytic-table,
.r-analytics .analytic-table th,
.r-analytics .analytic-table td {
    border: 1px solid #000;
}

.r-analytics .source-table {
    max-width: 90%;
    overflow-x: auto;
}

.r-analytics h1 {
    margin-top: 50px;
    font-size: 25px;
}

.r-analytics .filter-status {
    margin-left: 10px;
    border: 1px solid #000;
    border-radius: 5px;
}

.r-analytics input[type=date] {
    height: 40px;
}

.r-analytics .section {
    display: flex;
}

.r-analytics .section .source-table {
    margin-right: 30px;
    width: 45%;
}

.r-analytics .react-daterange-picker__wrapper {
    height: 38px;
    border-radius: 5px;
    margin: auto 5px;
}

.r-analytics .data td {
    color: #000;
}

.r-analytics .compare {
    background-color: #dcdcdc;
}

.r-analytics .compare:hover {
    background-color: #dcdcdc;
}