.sendNotification {
    margin-top: 1rem;
}

.sendNotification .input-block {
    margin-top: 1rem;
    width: 30%;
}

.sendNotification input {
    padding: 0.5rem;
    width: 100%;
}

.sendNotification .send {
    margin-top: 2rem;
    padding: 0.7rem;
    color: black;
}

@media screen and (max-width: 475px) {
    .sendNotification .input-block {
        width: 100%;
    }
}
