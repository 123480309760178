@font-face {
    font-family: FontAwesome;
    src: url('/public/assets/fonts/fontawesome/FontAwesome.otf');
}

.IconTooltip {
    margin-right: 35px;
    display: inline-block;
    position: relative;
    font-size: 15px;
    cursor: pointer;
}

.IconTooltip:last-child {
    margin-right: 0;
}

.IconTooltip i {
    color: #212121;
}

.IconTooltip .tooltip {
    opacity: 0.9;
    padding: 3px 8px;
    background: #212121;
    color: white;
    opacity: 1;
    top: -25px;
    width: 80px;
    text-align: center;
    left: -32px;
}

.IconTooltip .tooltip::after {
    position: absolute;
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
    bottom: -12px;
    left: -2.5px;
    content: "\F0DD";
    font-family: "FontAwesome";
    font-size: 25px;
    color: #212121;
    background-color: transparent;
    cursor: default;
}

.IconTooltip .hidden {
    opacity: 0;
}
