.input-search {
    width: 90%;
    height: 50px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #f5f5f5; 
    padding-left: 15px;
    margin: 10px 0
  
  }

.logo-skycall-voice {
  width: 30px;
  margin-top: -15px;
  margin-left: 5px;
  position: absolute;
}

.priority-badge {
  position: absolute;
  font-size: 10px;
  background: rgb(251, 78, 78);
  color: white;
  padding: 4px;
  border-radius: 5px;
  margin: 0px 5px;
  margin-top: -15px;
  margin-left: 5px;
}
  