.panel-default {
    background: #fff;
    margin-bottom: 20px;
    border: none;
    border-radius: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    padding: 15px 15px 0;
}

.panel-default .panel-heading {
    border: none;
    color: inherit;
    border-radius: 0;
    margin: -15px -15px 0;
    padding: 20px 15px;
    position: relative;
}

.panel-default .panel-heading h6 {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #212121;
}

.panel-default .panel-heading .right {
    position: absolute;
    right: 15px;
    top: 20px;
    font-size: 16px;
    line-height: 26px;
}

.panel-default .panel-body {
    padding-left: 0;
    padding-right: 0;
}

.table > thead > tr > th {
    color: #212121;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.42857143;
    padding: 16px;
    vertical-align: middle;
}

.table > thead > tr > th.sortable {
    cursor: pointer;
}

.table > thead > tr > th.sortable:hover {
    text-decoration: underline;
}

.table > thead > tr > th i {
    padding-left: 8px;
    font-size: 12px;
}

.table > tbody > tr > td {
    padding: 16px;
    vertical-align: middle;
}

i.danger {
    color: #f33923;
}

i.success {
    color: #8bc34a;
}

span.textCounter {
    font-size: 24px;
    color: #212121;
    display: block;
}

span.subtitle {
    font-weight: 500;
    display: block;
    font-size: 13px;
    text-transform: uppercase;
}

button.backBtn {
    margin-bottom: 20px;
    color: #212121;
    background: #f5f5f5;
    border: none;
    outline: none;
    font-size: 24px;
    width: 100%;
    height: 70px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
}


.prepharmacy-table {
    max-width: 1600px !important;
    width: 1600px !important;
}

.table-responsive {
    min-height: 0.01%;
    overflow-x: auto;
    max-width: 80vw;
}

@media screen and (max-width: 1024px) {
    .table-responsive {
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
        border: 1px solid #ddd;
    }
}


.swal2-cancel.btn.btn-danger {
    margin-right: 1em;
}

.btn {
    outline: none;
}

.custom .nav-tabs {
    border-bottom: 1px solid #dedede;
    margin-left: -15px;
    margin-right: -15px;

}

.custom .nav-tabs > li > a,
.custom .nav-tabs > li > a:hover,
.custom .nav-tabs > li.active > a,
.custom .nav-tabs > li.active > a:hover {
    background: transparent;
    border: none;
    outline: none;
    color: #878787;
    font-size: 100%;
    padding: 10px 20px;
}

.custom .nav-tabs > li.active > a,
.custom .nav-tabs > li.active > a:hover {
    border-bottom: 3px solid #2196f3;
    color: #212121;
}

.custom .nav-tabs > li > a:hover {
    color: #212121;
}

.custom .tab-content .active > .row {
    margin-top: 30px;
}

span.label {
    padding: 3px 6px 3px;
    font-size: 11px;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 400;
}

button.user-lock {
    border-radius: 8px;
    width: 100%;
    padding: 5px 0px;
    color: #fff;
    font-weight: 500;
    border: none;
    outline: none;
}

button.button-export {
    border: none;
    padding: 10px;
    background-color: #8d8d8d;
    margin-bottom: 10px;
    color: white;
}

button.user-lock.disable {
    opacity: 0.3;
}

button.user-lock.lock {
    background-color: #dc3545;
}

button.user-lock.unlock {
    background-color: green;
}

.log {
    padding-bottom: 3px;
    border-bottom: 1px dotted #212121;
    color: #212121;
    font-weight: 400;
}

.log .log-date {
    font-weight: 500;
    color: #212121;
}

.log.success .log-date {
    color: #8bc34a;
}

.log.error .log-date {
    color: #dc3545;
}
.border-table,
.border-table th,
.border-table td {
    border: 1px solid #000;
}