.CardCount.panel-default {
    padding: 0;
    text-align: center;
    color: #fff
}

.CardCount .panel-body {
    padding: 0;
}

.CardCount .textCounter {
    font-size: 24px;
    color: #212121;
    display: block;
    color: #fff;
}

.CardCount .textCounter span {
    font-size: 18px;
    color: #fff;
}

.CardCount .subtitle {
    font-weight: 500;
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    height: 30px;
    color: #fff;
    margin-bottom: 0;

}

.CardCount .nopad {
    padding-left: 0;
    padding-right: 0;
    padding-top: 19px;
    padding-bottom: 19px;
    min-height: 50px;
}

.CardCount i {
    color: #fff;
    display: inline-block;
    font-size: 30px;
    padding-top: 10px;
}