.campagne-sdr {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 90%;
}

.campagne-sdr textarea {
    resize: none;
    padding: 10px;
    height: 80px;
}

.campagne-sdr .sdr-block {
    display: flex;
    gap: 15px;
}

@media screen and (max-width: 1199px) {
    .campagne-sdr .sdr-block {
        flex-direction: column;
    }
}