.NotificationManager {
    top: 20px;
    right: 40px;
    position: fixed;
    z-index: 10;
    width: 300px;
    pointer-events: none;
}

.Notification {
    color: #fff;
    font-weight: 600;
    border-radius: 2px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    display: block;
    width: 100%;
    padding: 10px;
    line-height: 17px;
    position: relative;
    pointer-events: all;
    margin-bottom: 1em;
}

.Notification h2 {
    font-size: 16px;
    margin: 0 0 7px;
}
.Notification p {
    margin: 0;
    font-size: 12px;
}

.Notification .closeNotif {
    position: absolute;
    top: 3px;
    right: 7px;
    font-size: 14px;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeInDown {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    animation-duration: 0.75s;
    animation-name: bounceIn;
}

@keyframes bounceOut {
    20% {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.bounceOut {
    animation-duration: 0.75s;
    animation-name: bounceOut;
}
