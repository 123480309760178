.user-analytics h2 {
    margin: 60px 10px 0px;
}

.user-analytics .total {
    text-align: center;
    font-size: 40px;
    line-height: 40px;
}

.user-analytics h5 {
    text-align: center;
    border-bottom: 1px solid #000;
}

.user-analytics p {
    text-align: center;
}

.user-analytics .user-table td {
    border: none;
    padding: 10px;
    text-align: center;
}

.user-analytics .user-table th {
    text-align: center;
}

.user-analytics .by-period .heading>div {
    display: flex;
    margin-bottom: 10px;
}

.user-analytics .heading .submit-button {
    margin-left: 50px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    height: 38px;
}

.user-analytics .compare, .user-analytics .compare:hover {
    background-color: #dcdcdc;
}
