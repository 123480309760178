.conversion-tunnel {
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
}

.block-1 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.block-2 {
    width: 52%;
}

.select-block {
    display: flex;
    gap: 20px;
}

.card-block {
    display: flex;
    flex-direction: column;
}

.card-block .CardCount {
    width: 430px;
}

.card-block .type-block {
    display: flex;
    gap: 15px;
    align-items: center;
}

@media screen and (max-width: 991px) {
    .conversion-tunnel {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (max-width: 488px) {
    .select-block, .card-block .type-block {
        flex-direction: column;
    }

    .card-block, .card-block .p, .card-block .CardCount {
        width: 95%;
    }
}