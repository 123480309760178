.ProfilePanel.panel.panel-default {
    padding: 0;
    text-align: center;
}

.ProfilePanel .cover {
    background: #7e7e7e;
    position: relative;
    min-height: 197px;
}

.ProfilePanel .profileImage {
    border-radius: 2px;
    height: 143px;
    margin: -80px auto 0;
    padding: 4px;
    position: relative;
    width: 143px;
    background: #fff;
}

.ProfilePanel .profileImage img {
    width: 135px;
    height: 135px;
    display: inline-block;
    margin-bottom: 10px;
}

.ProfilePanel .profileInfo h5 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: capitalize;
    display: block;
}

.ProfilePanel .profileInfo h6 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
    color: #212121;
}

.ProfilePanel .infos {
    position: relative;
    margin-bottom: 20px;
}

.ProfilePanel .infos span.subtitle {
    position: relative;
    text-transform: initial;
    font-size: 100%;
}

.ProfilePanel .doctorActive {
    margin-top: 40px;
    background: #8BC34A;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 10px 0;
}

.ProfilePanel .doctorActive::after {
    content: 'Activé';
}

.ProfilePanel .doctorActive:hover {
    background: rgb(153, 202, 98);
}

.ProfilePanel button.DoctorWaiting {
    margin-top: 40px;
    background: #2df8a3;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 10px 0;
    outline: none;
    display: block;
    width: 100%;
    transition: background 0.3s;
}

.ProfilePanel button.DoctorWaiting.deactive {
    background: #F8B32D;
}


.EvaluateTest {
    margin-top: 10px;
    margin-bottom: 10px;
    background: #F8B32D;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 10px 0;
    outline: none;
    display: block;
    width: 50%;
    text-align: center;
    margin-left: 20%;
    transition: background 0.3s;
}

.EvaluateTest:hover {
    background: rgb(255, 205, 104);
}